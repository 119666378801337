import {PriceModel} from './Price.model';
import {GiftCardFragment} from '../../gql/graphql';

export class GiftCardModel {
  public price: PriceModel;
  public id: string;
  public obfuscatedCode: string;

  constructor(giftCard: GiftCardFragment) {
    this.price = new PriceModel(giftCard.amount);
    this.id = giftCard.id!;
    this.obfuscatedCode = giftCard.obfuscatedCode!;
  }
}
