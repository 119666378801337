import {ViolationTargetFragment} from '../../gql/graphql';
import {ViolatioOtherTargetModel} from './ViolatioOtherTargetModel.model';
import {ViolationLineItemTargetModel} from './ViolationLineItemTargetModel.model';

export class ViolationTargetModel {
  public lineItemTarget?: ViolationLineItemTargetModel;
  public otherTarget?: ViolatioOtherTargetModel;
  constructor(target: ViolationTargetFragment) {
    this.lineItemTarget = target?.lineItem ? new ViolationLineItemTargetModel(target.lineItem) : undefined;
    this.otherTarget = target?.other ? new ViolatioOtherTargetModel(target.other) : undefined;
  }
}
