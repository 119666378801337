import {SubscriptionOptionInfoFragment, WixPaymentApiPayV2SubscriptionFrequency} from '../../gql/graphql';

export class SubscriptionModel {
  public name: string;
  public isAutoRenewal: boolean;
  public frequency: WixPaymentApiPayV2SubscriptionFrequency;
  public interval: number;
  public billingCycles: number;

  constructor({title, subscriptionSettings}: SubscriptionOptionInfoFragment) {
    this.name = title?.translated ?? title?.original ?? '';
    this.isAutoRenewal = Boolean(subscriptionSettings?.autoRenewal);
    this.frequency =
      (subscriptionSettings?.frequency as WixPaymentApiPayV2SubscriptionFrequency) ??
      WixPaymentApiPayV2SubscriptionFrequency.UNDEFINED;
    this.interval = subscriptionSettings?.interval ?? 1;
    this.billingCycles = subscriptionSettings?.billingCycles ?? 0;
  }
}
