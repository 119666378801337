import {ItemMediaModel} from './ItemMedia.model';
import {ItemPricesModel} from './ItemPrices.model';
import {ItemTypeModel} from './ItemType.model';
import {LineItemOptionModel} from './LineItemOption.model';
import {SubscriptionModel} from './Subscription.model';
import {
  ComWixEcomMembershipsSpiV1HostMembership,
  ComWixEcommerceCatalogSpiApiV1PaymentOptionType,
  EcomCheckoutV1ItemAvailabilityStatus,
  EnrichedLineItemFragment,
  ItemAvailabilityInfoFragment,
  LineItemFragment,
  MembershipFragment,
  MembershipOptionsFragment,
  MultiCurrencyPriceFragment,
  RenderingConfigFragment,
} from '../../gql/graphql';

export class LineItemModel {
  public id: string;
  public catalogAppId?: string;
  public productName: string;
  public media?: ItemMediaModel;
  public prices: ItemPricesModel;
  public options: LineItemOptionModel[];
  public quantity: number;
  public subscription?: SubscriptionModel;
  public itemType: ItemTypeModel;
  public renderingConfig?: RenderingConfigFragment;
  public sku?: string;
  public selectedMembership?: MembershipFragment;
  public paymentOption?: ComWixEcommerceCatalogSpiApiV1PaymentOptionType;
  public depositAmount?: MultiCurrencyPriceFragment;

  // eslint-disable-next-line sonarjs/cognitive-complexity
  constructor(
    lineItem: LineItemFragment,
    membershipOptions?: MembershipOptionsFragment,
    enrichedLineItems?: (EnrichedLineItemFragment | null)[] | null
  ) {
    const selectedMembership = findSelectedMembership(membershipOptions, lineItem.id);
    const enrichedItem = findEnrichedItem(enrichedLineItems, lineItem.id);
    const mergedLineItem = mergeEnrichedItem(lineItem, enrichedItem);
    const {
      id,
      media,
      productName,
      lineItemPrice,
      price,
      fullPrice,
      priceBeforeDiscounts,
      catalogReference,
      quantity,
      subscriptionOptionInfo,
      itemType,
      availability,
      physicalProperties,
      descriptionLines,
      paymentOption,
      depositAmount,
    } = mergedLineItem;

    this.id = id!;
    this.catalogAppId = catalogReference?.appId ?? /* istanbul ignore next */ undefined;
    this.itemType = new ItemTypeModel(itemType!);
    this.productName = productName?.translated ?? productName?.original ?? '';
    this.media = media && (media.id || media.url) ? new ItemMediaModel(media, this.productName) : undefined;
    this.prices = new ItemPricesModel({
      lineItemPrice: lineItemPrice ?? /* istanbul ignore next */ undefined,
      price: price ?? /* istanbul ignore next */ undefined,
      fullPrice: fullPrice ?? /* istanbul ignore next */ undefined,
      priceBeforeDiscounts: priceBeforeDiscounts ?? /* istanbul ignore next */ undefined,
    });
    this.options = descriptionLines
      ? LineItemOptionModel.convertToOptionsList(descriptionLines)
      : /* istanbul ignore next */ [];
    this.quantity = calculateQuantity(availability!, Number(quantity));
    this.subscription = subscriptionOptionInfo ? new SubscriptionModel(subscriptionOptionInfo) : undefined;
    this.sku = physicalProperties?.sku ?? undefined;
    this.renderingConfig = enrichedItem
      ? enrichedItem.renderingConfig ?? /* istanbul ignore next */ undefined
      : undefined;
    this.selectedMembership = selectedMembership;
    this.paymentOption = paymentOption ?? /* istanbul ignore next */ undefined;
    this.depositAmount = depositAmount ?? /* istanbul ignore next */ undefined;
  }
}

const calculateQuantity = (availability: ItemAvailabilityInfoFragment, quantity: number): number => {
  if (availability.status === EcomCheckoutV1ItemAvailabilityStatus.NOT_AVAILABLE) {
    return 0;
  }
  if (availability.status === EcomCheckoutV1ItemAvailabilityStatus.PARTIALLY_AVAILABLE) {
    return availability.quantityAvailable!;
  }
  return quantity;
};

function findEnrichedItem(enrichedLineItems?: (EnrichedLineItemFragment | null)[] | null, lineItemId?: string | null) {
  if (!enrichedLineItems) {
    return undefined;
  }
  return enrichedLineItems.find((enrichedItem) => enrichedItem?.id === lineItemId);
}

function findSelectedMembership(
  membershipOptions?: MembershipOptionsFragment | null,
  lineItemId?: string | null
): ComWixEcomMembershipsSpiV1HostMembership | undefined {
  if (!membershipOptions || !lineItemId) {
    return undefined;
  }
  return (
    membershipOptions.eligibleMemberships?.find((m) => m?.lineItemIds?.includes(lineItemId)) ??
    /* istanbul ignore next */ undefined
  );
}

export function mergeEnrichedItem(lineItem: LineItemFragment, enrichedItem?: EnrichedLineItemFragment | null) {
  if (!enrichedItem) {
    return lineItem;
  }
  const overriddenDescriptionLines = enrichedItem?.overriddenDescriptionLines?.descriptionLines;
  return {
    ...lineItem,
    ...enrichedItem,
    ...(overriddenDescriptionLines ? {descriptionLines: overriddenDescriptionLines} : /* istanbul ignore next */ {}),
  };
}
