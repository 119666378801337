import {CheckboxesStoreProps} from '../../types/app.types';
import {CheckboxesService} from '../services/CheckboxesService';
import {CheckoutService} from '../services/CheckoutService';
import {CheckoutSettingsService} from '../services/CheckoutSettingsService';

export class CheckboxesStore {
  private readonly checkoutService: CheckoutService;
  private readonly checkoutSettingsService: CheckoutSettingsService;
  private readonly checkboxesService: CheckboxesService;
  private readonly updateComponent: () => void;

  constructor({
    checkoutService,
    checkoutSettingsService,
    checkboxesService,
    updateComponent,
  }: {
    checkoutService: CheckoutService;
    checkoutSettingsService: CheckoutSettingsService;
    checkboxesService: CheckboxesService;
    updateComponent: () => void;
  }) {
    this.checkoutService = checkoutService;
    this.checkoutSettingsService = checkoutSettingsService;
    this.checkboxesService = checkboxesService;
    this.updateComponent = updateComponent;
  }

  private readonly areCheckboxesValid = (): boolean => {
    return (
      (!this.checkoutSettingsService.checkoutSettings.areGeneralPoliciesEnabled || this.isPolicyCheckboxChecked()) &&
      (!this.checkoutService.checkout.hasDigitalItems ||
        !this.checkoutSettingsService.checkoutSettings.isDigitalPolicyEnabled ||
        this.isDigitalPolicyCheckboxChecked())
    );
  };

  private readonly isPolicyCheckboxChecked = (): boolean => {
    return (
      this.checkboxesService.isPolicyCheckboxCheckedByUser ??
      this.checkoutSettingsService.checkoutSettings.arePoliciesCheckedByDefault
    );
  };

  private readonly isDigitalPolicyCheckboxChecked = (): boolean => {
    return (
      this.checkboxesService.isDigitalPolicyCheckboxCheckedByUser ??
      this.checkoutSettingsService.checkoutSettings.arePoliciesCheckedByDefault
    );
  };

  private readonly isSubscriptionCheckboxChecked = (): boolean => {
    return (
      this.checkboxesService.isSubscriptionCheckboxCheckedByUser ??
      this.checkoutSettingsService.checkoutSettings.isSubscriptionCheckedByDefault
    );
  };

  private readonly toggleIsPolicyCheckboxChecked = (checked: boolean): void => {
    this.checkboxesService.togglePolicyCheckboxChecked(checked);
    this.updateComponent();
  };

  private readonly toggleIsDigitalPolicyCheckboxChecked = (checked: boolean): void => {
    this.checkboxesService.toggleDigitalPolicyCheckboxChecked(checked);
    this.updateComponent();
  };

  private readonly toggleIsSubscriptionCheckboxChecked = (checked: boolean): void => {
    this.checkboxesService.toggleSubscriptionCheckboxChecked(checked);
    this.updateComponent();
  };

  private readonly toggleWasFormSubmitted = (wasSubmitted: boolean): void => {
    this.checkboxesService.toggleWasFormSubmitted(wasSubmitted);
    this.updateComponent();
  };

  private readonly shouldShowCheckboxesSection = (): boolean => {
    return (
      this.checkoutSettingsService.checkoutSettings.areGeneralPoliciesEnabled ||
      this.checkoutSettingsService.checkoutSettings.isDigitalPolicyEnabled ||
      this.checkoutSettingsService.checkoutSettings.isSubscriptionEnabled
    );
  };

  public toProps(): CheckboxesStoreProps {
    return {
      isPolicyCheckboxChecked: this.isPolicyCheckboxChecked(),
      toggleIsPolicyCheckboxChecked: this.toggleIsPolicyCheckboxChecked,
      isDigitalPolicyCheckboxChecked: this.isDigitalPolicyCheckboxChecked(),
      toggleIsDigitalPolicyCheckboxChecked: this.toggleIsDigitalPolicyCheckboxChecked,
      isSubscriptionCheckboxChecked: this.isSubscriptionCheckboxChecked(),
      toggleIsSubscriptionCheckboxChecked: this.toggleIsSubscriptionCheckboxChecked,
      areCheckoutCheckboxesValid: this.areCheckboxesValid(),
      wasFormSubmitted: this.checkboxesService.wasFormSubmittedByUser,
      setWasFormSubmitted: this.toggleWasFormSubmitted,
      shouldShowCheckboxesSection: this.shouldShowCheckboxesSection(),
    };
  }
}
