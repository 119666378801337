import {CustomFieldFragment} from '../../gql/graphql';

export class CustomFieldModel {
  public title: string;
  public value: string;
  constructor(customField: CustomFieldFragment) {
    this.title = customField.translatedTitle ?? customField.title!;
    this.value = customField.value as string;
  }
}
