// eslint-disable-next-line wix-custom-rules/wixstores-file-names
import {ComWixEcommerceValidationsSpiV1TargetNameInLineItem, ViolationLineItemTargetFragment} from '../../gql/graphql';

export class ViolationLineItemTargetModel {
  public name?: ViolationLineItemTargetName;
  public id?: string;
  constructor(lineItemTarget?: ViolationLineItemTargetFragment) {
    this.name = lineItemTarget?.name
      ? toViolationLineItemTargetName(lineItemTarget?.name)
      : /* istanbul ignore next */ undefined;
    this.id = lineItemTarget?.id ?? undefined;
  }
}

export enum ViolationLineItemTargetName {
  lineItemDefault = 'LINE_ITEM_DEFAULT',
}

const toViolationLineItemTargetName = (otherTargetName: ComWixEcommerceValidationsSpiV1TargetNameInLineItem) => {
  // eslint-disable-next-line sonarjs/no-small-switch
  switch (otherTargetName) {
    case ComWixEcommerceValidationsSpiV1TargetNameInLineItem.LINE_ITEM_DEFAULT:
      return ViolationLineItemTargetName.lineItemDefault;
  }
};
