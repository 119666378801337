import {DeliveryMethodStoreProps} from '../../types/app.types';
import {SiteStore} from '@wix/wixstores-client-storefront-sdk';
import {ControllerFlowAPI} from '@wix/yoshi-flow-editor';
import {CheckoutService} from '../services/CheckoutService';
import {BIService} from '../services/BIService';
import {FedopsInteractions} from '../../components/Checkout/constants';
import {NavigationService} from '../services/NavigationService';

export type DeliveryMethodStoreConfig = {
  flowAPI: ControllerFlowAPI;
  siteStore: SiteStore;
  checkoutService: CheckoutService;
  navigationService: NavigationService;
  biService: BIService;
  updateComponent: () => void;
};

export class DeliveryMethodStore {
  private readonly flowAPI: ControllerFlowAPI;
  private readonly siteStore: SiteStore;
  private readonly checkoutService: CheckoutService;
  private readonly navigationService: NavigationService;
  private readonly biService: BIService;
  private readonly updateComponent: () => void;
  private isUpdatingShippingOption: boolean;

  constructor({
    flowAPI,
    siteStore,
    checkoutService,
    biService,
    updateComponent,
    navigationService,
  }: DeliveryMethodStoreConfig) {
    this.flowAPI = flowAPI;
    this.siteStore = siteStore;
    this.checkoutService = checkoutService;
    this.navigationService = navigationService;
    this.biService = biService;
    this.updateComponent = updateComponent;
    this.isUpdatingShippingOption = false;
  }

  private readonly setShippingOption = async (shippingOptionId: string): Promise<void> => {
    this.flowAPI.fedops.interactionStarted(FedopsInteractions.ChangeDeliveryMethodInteraction);
    this.isUpdatingShippingOption = true;
    this.updateComponent();
    await this.checkoutService.setShippingOption(shippingOptionId);
    this.isUpdatingShippingOption = false;
    this.updateComponent();
    this.flowAPI.fedops.interactionEnded(FedopsInteractions.ChangeDeliveryMethodInteraction);
  };

  private readonly beforePlaceOrderClicked = (): void => {
    this.navigationService.isFastFlow &&
      this.biService.deliveryMethodSet(this.checkoutService.checkout, this.checkoutService.originalShippingTitle);
  };

  private readonly hasShippingOptions = (): boolean => {
    return (
      !!this.checkoutService.checkout.shippingOptions.length || !!this.checkoutService.checkout.pickupOptions.length
    );
  };

  public toProps(): DeliveryMethodStoreProps {
    return {
      canShipToDestination: !!this.checkoutService.checkout.selectedShippingOption,
      hasShippingOptions: this.hasShippingOptions(),
      isUpdatingShippingOption: this.isUpdatingShippingOption,
      isSelectedShippingOptionPickup: !!this.checkoutService.checkout.selectedShippingOption?.pickupInfo,

      setShippingOption: this.setShippingOption,
      beforePlaceOrderClicked: this.beforePlaceOrderClicked,
    };
  }
}
