import {ViolationOtherTargetName} from '../models/ViolatioOtherTargetModel.model';
import {ViolationModel, ViolationSeverity} from '../models/Violation.model';

export const hasErrorViolations = (violations: ViolationModel[]): boolean => {
  return violations.some((violation) => violation.severity === ViolationSeverity.error);
};

export const calcTopThreeViolations = (violations: ViolationModel[]): ViolationModel[] => {
  return [
    ...violations.filter(
      (violation) =>
        violation.severity === ViolationSeverity.error &&
        violation.target?.otherTarget?.name === ViolationOtherTargetName.default
    ),
    ...violations.filter(
      (violation) =>
        violation.severity === ViolationSeverity.warning &&
        violation.target?.otherTarget?.name === ViolationOtherTargetName.default
    ),
  ]
    .slice(0, 3)
    .reverse();
};

export const calcVisibleViolations = (violations: ViolationModel[]): ViolationModel[] => {
  const top3violations = calcTopThreeViolations(violations);
  return [
    ...top3violations,
    ...violations.filter(
      (violation) => violation.severity === ViolationSeverity.error && Boolean(violation.target?.lineItemTarget?.id)
    ),
    ...violations.filter(
      (violation) => violation.severity === ViolationSeverity.warning && Boolean(violation.target?.lineItemTarget?.id)
    ),
  ];
};
