import {Payments, StoreCheckoutSettings, LocalDeliveryByZipCodeFlag} from '@wix/wixstores-graphql-schema-node';
import {query} from './getCheckoutSettings.graphql';
import {query as deprecatedQuery} from './getCheckoutSettings.deprecated.graphql';

import {BaseApi, GraphQLOperations, RemoteSourceTypes} from '@wix/wixstores-client-storefront-sdk';
import {StoreCheckoutSettingsAndPayments} from '../utils/types.util';
import {SPECS} from '../../components/Checkout/constants';

export class CheckoutSettingsApi extends BaseApi {
  public async getCheckoutSettings(): Promise<StoreCheckoutSettingsAndPayments> {
    const useNewQuery = this.siteStore.experiments.enabled(SPECS.SupportMitEnabledFieldInCheckoutPage);
    const response = await this.fetch<
      Record<string, never>,
      {
        checkoutSettings: StoreCheckoutSettings;
        payments: Payments;
        localDeliveryByZipCodeFlag: LocalDeliveryByZipCodeFlag;
      }
    >(
      {
        query: useNewQuery ? query : deprecatedQuery,
        variables: {},
        operationName: GraphQLOperations.GetCheckoutSettings,
        source: 'WixStoresWebClient',
      },
      RemoteSourceTypes.NodeReadWrite
    );

    return {
      checkoutSettings: response.data.checkoutSettings,
      payments: response.data.payments,
      localDeliveryByZipCodeFlag: response.data.localDeliveryByZipCodeFlag,
    };
  }
}
