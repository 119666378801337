import {SiteStore} from '@wix/wixstores-client-storefront-sdk';
import {CheckoutSettingsApi} from '../apis/CheckoutSettingsApi';
import {CheckoutSettingsModel} from '../models/checkoutSettings/CheckoutSettings.model';
import {IWidgetControllerConfig} from '@wix/native-components-infra';
import {CheckoutOrigin} from '../utils/bi.util';
import {getForm} from '@wix/ambassador-forms-v4-form/http';
import {FORMS_TEMPLATE_IDS_USED_WITH_CHECKOUT_COMPOSER} from '../../components/Checkout/constants';
import {ambassadorWithHeaders} from '../utils/ambassador.utils';
import {ControllerFlowAPI} from '@wix/yoshi-flow-editor';

export class CheckoutSettingsService {
  private readonly controllerConfig: IWidgetControllerConfig;
  private readonly siteStore: SiteStore;
  private readonly checkoutSettingsApi: CheckoutSettingsApi;
  public checkoutSettings!: CheckoutSettingsModel;
  public showCouponSP!: boolean;
  public showExpressCheckoutSP!: boolean;
  public checkoutComposerEnabled!: boolean;
  public isExtendedFieldsFormEmpty!: boolean;
  private readonly flowAPI: ControllerFlowAPI;

  constructor({
    controllerConfig,
    siteStore,
    flowAPI,
  }: {
    controllerConfig: IWidgetControllerConfig;
    siteStore: SiteStore;
    flowAPI: ControllerFlowAPI;
  }) {
    this.controllerConfig = controllerConfig;
    this.siteStore = siteStore;
    this.flowAPI = flowAPI;
    this.checkoutSettingsApi = new CheckoutSettingsApi({siteStore, origin: CheckoutOrigin.checkout});
  }

  public async fetchSiteSettings(): Promise<void> {
    const checkoutSettingsAndPayments = await this.checkoutSettingsApi.getCheckoutSettings();
    this.checkoutSettings = new CheckoutSettingsModel({
      checkoutSettingsAndPayments,
      taxName: this.siteStore.taxName,
    });
  }

  public async fetchIsCheckoutComposerEnabled(): Promise<void> {
    const response = await this.controllerConfig.essentials.httpClient.get(
      '/_serverless/checkout-composer-experiment-api/isEnabled'
    );
    this.checkoutComposerEnabled = response.data as boolean;
  }

  public async fetchIsExtendedFieldsFormIsEmpty(): Promise<boolean> {
    const response = await ambassadorWithHeaders(
      getForm({formId: FORMS_TEMPLATE_IDS_USED_WITH_CHECKOUT_COMPOSER.EXTENDED_FIELDS_FORM_ID}),
      this.siteStore,
      this.flowAPI
    );
    const isEmpty = !response.data.form?.fields?.filter((f) => !f.hidden).length;
    this.isExtendedFieldsFormEmpty = isEmpty;
    return isEmpty;
  }

  public async init(): Promise<void> {
    await Promise.all([this.fetchSiteSettings(), this.fetchIsCheckoutComposerEnabled()]);
    if (this.checkoutComposerEnabled) {
      await this.fetchIsExtendedFieldsFormIsEmpty();
    }

    this.showCouponSP = this.controllerConfig.config.style.styleParams.booleans.checkout_showCoupon !== false;
    this.showExpressCheckoutSP =
      this.controllerConfig.config.style.styleParams.booleans.checkout_showExpressCheckout !== false;
  }
}
