import {CustomSettingsFragment} from '../../gql/graphql';

export class CustomSettingsModel {
  public hideGiftCard: boolean;
  public hideCoupon: boolean;

  constructor(customSettings?: CustomSettingsFragment | null) {
    this.hideGiftCard = customSettings?.lockGiftCard ?? false;
    this.hideCoupon = customSettings?.lockCouponCode ?? false;
  }
}
